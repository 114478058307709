import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    articles: [],
    currentArticle: null,
    mobileIsOpen: false,
    isMobileView: false,
    isAdding: false,
    showHinweis:false,
    showedHinweis: false,
  },
  actions: {

  },
  mutations: {
    SetMobileArticleOpen(state, mode) {
      state.mobileIsOpen = mode
    },
    SetAdding(state,mode){
      state.isAdding = mode;
    },
    SetShowHinweis(state,mode){
      state.showHinweis = mode;

      if(!mode){
        state.showedHinweis = true;
      }
      
    }
  }

})