<template>
  <div id="app">
    <!--<ComingSoon/>-->
    <div class="overlay">
      <div class="navigation">
        <NavigationMobile v-if="this.$store.state.isMobileView" />
        <Navigation v-if="!this.$store.state.isMobileView" />
      </div>
    </div>
    
    <div class="content">
      <router-view :key="$route.fullPath" />
      <CookiesPanel v-if="!checkCookies()" />
      <HinweisPanel v-if="this.$store.state.showHinweis && !this.$store.state.showedHinweis"/>
    </div>
  </div>
</template>  

<script>
import ComingSoon from "./components/ComingSoonView.vue";
import Navigation from "./components/Navigation";
import NavigationMobile from "./components/NavigationMobile";
import CookiesPanel from "./components/CookiesPanel";
import HinweisPanel from "./components/HinweisPanel"
//import mainConfig from "./main.config.js"

export default {
  name: "App",
  data: () => {
    return {
      mobileView: true,
    };
  },
  methods: {
    handleView() {
      this.$store.state.isMobileView = window.innerWidth <= 600;
    },
    checkCookies() {
      // if(mainConfig.appBuild){
      //   return false;
      // }
      var stringList = document.cookie.split(";");
      var cookiesAccepted = stringList.includes("clicky");

      if (cookiesAccepted) {
        var node = document.createElement("script"); // is a node
        node.innerHTML =
          "var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(101299226)";
        document.head.appendChild(node);
      }
      return cookiesAccepted;
    },
  },
  components: {
    ComingSoon,
    Navigation,
    NavigationMobile,
    CookiesPanel,
    HinweisPanel
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
  mounted() {
    this.handleView();
  },
};
</script>

<style lang="scss">
</style>
