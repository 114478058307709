<template>
  <div id="navigation-mobile">
    <div id="header">
      <div class="header-item" id="logo-container"> 
        <router-link to="/"><Logo id="logo" class="dark" /></router-link>
      </div>
      <div class="header-item" @click="showNav = !showNav">
        <!--<i class="fas fa-bars"></i>-->
        <pommesMenu id="navigation-icon" :class="'rotate-' + showNav" />
      </div>
      
    </div>

    <div id="dropdown" v-if="showNav">
      <ul>
        <li @click="showNav = !showNav">
          <router-link to="/map">Karte</router-link>
        </li>
        <li @click="showNav = !showNav">
          <router-link to="/submit" class="machmit">Mach Mit!</router-link>
        </li>
        <li @click="showNav = !showNav">
          <router-link to="/aboutus">About</router-link>
        </li>
        <li @click="showNav = !showNav">
          <a href="https://www.instagram.com/frittenradar/" target="_blank">
            <instaIcon class="instaIcon"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Logo from "../SVG/wortmarke_v1.1.svg";
import instaIcon from "../SVG/icon_insta.svg";
import pommesMenu from "../SVG/PommesMenu.svg";

export default {
  name: "NavigationMobile",
  components: {
    Logo,
    instaIcon,
    pommesMenu,
  },
  data: () => {
    return {
      showNav: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import url("https://use.fontawesome.com/releases/v5.9.0/css/all.css");

#navigation-mobile {
  font-size: 1rem;
  left: 0;
  top: 0;

  ul {
    list-style: none;
    li {
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
#header{
    display: flex;
    justify-items:flex-end;
}
.header-item {
  display: inline-block;
}

#logo-container{
  width: 90%;
  height:auto;
}
#navigation-icon {
  width: 40px;
  height:auto;
  display: inline;
  transition: transform 0.2s;
}

.rotate-true {
  transform: rotate(-90deg);
}
.rotate-false {
  transform: rotate(0deg);
}

#dropdown {
  position: absolute;
  right: 0;
  top: $navigation-height;
  width: 100%;
  padding-right: 20px;
  background-color: $background-color;
 float: right;
}

ul{
  text-align: right;
}

.menuIcon {
  height: $navigation-height;
}

#logo {
  display: inline;
}

.social-media {
  a {
    padding-right: 10px;
  }
}

.instaIcon {
  width: 25px;
}
.machmit{
word-spacing: 0.3rem;;
}
</style>