import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue} from 'bootstrap-vue'
import '../public/main.scss'
import { store } from './store'
import VueRouter from 'vue-router'
import Map from './components/Map'
import AboutUs from './components/AboutUs'
import Mitmachen from './components/Mitmachen'
import DataPolicy from './components/DataPolicy'
import Imprint from './components/Imprint'
import vueconfig from '../vue.config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueInstagram from 'vue-instagram'
 
Vue.use(VueInstagram)

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueRouter)

// Install BootstrapVue
Vue.use(BootstrapVue)

Vue.config.productionTip = false

const routes = [
  { path: '/', redirect: '/map' },
  { path: '/map', name:'map', component: Map },
  { path: '/map/:id', name:'article', component: Map },
  { path: '/aboutus', name:'aboutus', component: AboutUs},
  { path: '/submit', name:'submit', component: Mitmachen},
  { path: '/datapolicy', name:'datapolicy', component: DataPolicy},
  { path: '/imprint', name:'imprint', component: Imprint},
  // { 
  //   path: '/:catchAll(.*)', 
  //   component: Map,
  //   name: 'map'
  // }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: "history",
  routes // short for `routes: routes`
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
