<template>
  <div class="mainPages">
    <h3>About Frittenradar</h3>
<div class="AboutUs">
<p>Frittenradar – euer Wegweiser zur nächsten Pommesbude. 
Ihr seid zu Besuch in der Stadt, kennt euch noch nicht so gut aus oder wollt einfach mal neue Seiten euer Stadt erkunden? Egal, ob ihr gezielt nach der nächsten Pommesbude sucht oder auf Entdeckungstour gehen wollt. Mit Frittenradar findet ihr definitiv den Weg zu der nächsten Portion Fritten. Übrigens, so ist auch die Idee für diese Seite entstanden - auf der Suche nach Pommes.
<br>
<br>
Was uns wichtig ist: Frittenradar ist keine traditionelle Bewertungsplattform für Pommesbuden. Wir werden keine Bestenliste ausrufen, da wir keinen Bock auf Formate wie »die zehn besten Buden der Stadt« haben. Wir feiern Fritten in all ihren Farben, Formen und Facetten.  
Natürlich sind Pommes mal geiler und mal weniger, aber die Bewertungen spielen für uns eine untergeordnete Rollen und sind vor allem als Information zu verstehen. Außerdem ist diese Bewertung nur die subjektive Einschätzung der Autor:innen und das ist auch gut so.  
<br>
<br>
Was uns noch wichtiger ist: so viele Informationen zu Pommesbuden wie möglich bereitzustellen. Frittenradar versteht sich als Plattform, um mit euch FrittenLover:innen in Kontakt und Austausch zu kommen und so das Projekt und diese Seite co-creative weiterzuentwickeln. Dieser Community-Gedanke ist uns wichtig. 
<br>
<h3>Macht mit!</h3> 
<p>Frittenradar lebt von FrittenLover:innen wie euch und uns. Daher sind wir auf eure Beiträge angewiesen: Wenn ihr eine Imbissbude oder einen Frittenladen kennt, der noch nicht auf der Karte verzeichnet ist, nutzt gerne unser <a href="/submit"> Mitmachen-Formular</a> oder schreibt eine E-Mail an <a href="mailto:pommes@frittenradar.de">pommes[at]frittenradar.de</a>.  
<p>Zusammen haben wir schon <b>{{this.$store.state.articles.length}}</b> Frittenbuden markiert!</p>
<p>
Folgt uns auf <a href="https://www.instagram.com/frittenradar/" target="_blank" rel=“noopener“>Instagram</a> für die neuesten Beiträge und einen Einblicke hinter die Kulissen von Frittenradar. 
</p>

<!--<h3>Blog</h3> 

<p>
In den letzten Monaten, in denen wir kreuz und quer durch Berlin sind, um Fritten zu essen, sind wir mit vielen Menschen ins Gespräch gekommen. Einige dieser Geschichten, die sich hinter diesen Orten verstecken, wollen wir bald in längeren Beiträgen in einem Blog vorstellen. 
</p>-->
<h3>Wer wir sind</h3> 
<p>Wir sind Lea,Jannik und Paul - eine Crew von Freund:innen, die an Frittenradar in ihrer Freizeit rumbasteln. Im weiteren Sinne stehen hinter Frittenradar jedoch eine größere Gruppe von Freund:innen, Autor:innen und Beitragenden, die Frittenradar zu dem machen, was es in erster Linie ist: Eine Wertschätzung für gute Fritten. Danke an Klara für die Zeichnungen!
</p>
    <div class="links">
      <router-link class="link" to="/datapolicy">Datenschutz</router-link>
      <router-link class="link" to="/imprint"> Impressum</router-link>
    </div>
  </div>
  </div>
</template>

<script>
import VueInstagram from 'vue-instagram'
export default {
  name: "AboutUs",
  components: {VueInstagram},
};
</script>

<style lang="scss" scoped>
@import "../style/pages.scss";
.links{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5em;
}

.link{
  padding-right: 10px;
}



.AboutUs{
  width: 66%;
}
@media only screen and (max-width: 1024px) {
  
  .AboutUs {
  width: 100%;
  
}

  }

</style>