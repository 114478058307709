module.exports = {
  //publicPath : "/beta",
  devServer: {
    https: true
  },

  chainWebpack: config => {
    config.module.rules.delete("svg");
  },

  css: {
    loaderOptions: {
      sass: {
        additionalData: `@import "./src/style/variables.scss";`
      }
    }
  },

  configureWebpack: {
    module: {
      rules: [

        {
          test: /\.(bin)$/,
          use: [
            {
              loader: 'file-loader',
              options: {}
            }
          ]
        },
        {
          test: /\.svg$/,
          use: [
            'babel-loader',
            'vue-svg-loader',
          ],
        }
      ]
    },

  },

  // publicPath: '',

  // pluginOptions: {
  //   cordovaPath: 'src-cordova'
  // }
}
