<template>
  <div id="navigation">
    <!--<div id="logo">FRITTENRADAR</div>-->
    <router-link to="/"><Logo id="logo" class="dark"/></router-link>
    <ul>
      <li>
        <a
          href="https://www.instagram.com/frittenradar/"
          target="_blank"
          >
          <instaIcon class="instaIcon dark"/></a>
      </li>
      <li><router-link to="/aboutus">About</router-link></li>
      <li><router-link to="/submit" class="machmit">Mach Mit!</router-link></li>
      <li><router-link to="/map">Karte</router-link></li>
      
      
    </ul>
  </div>
</template>

<script>
import Logo from "../SVG/wortmarke_v1.1.svg";
import instaIcon from "../SVG/icon_insta.svg";

export default {
  name: "Navigation",
  components: {
    Logo,
    instaIcon,
  },
};
</script>
<style lang="scss" scoped>
#navigation {
  width: 100%;
  font-size: 1rem;
}

ul {
  list-style: none;
  display: inline;
  li {
    font-weight: bold;
    float: right;
    padding-left: 20px;
  }
}

.instaIcon{
  width:25px;
}

.machmit{
  word-spacing: 0.3rem;;
}
</style>