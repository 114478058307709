<template>
  <div @click="Close()">
    <h1>{{ this.$store.state.currentArticle.title }}</h1>

    <ArticleContent />

  </div>
</template>

<script>

import ArticleContent from "./ArticleContent"

import articleMixin from "../Mixins/article"
export default {
  name: "Article",
  components: {
    Map,
    ArticleContent
  },
  mixins :[articleMixin],
  methods: {
    Close() {
      this.CloseArticle();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";
.singleLine {
  margin-bottom: 0;
}


.article {
  background-color: $transparent-light;
}
</style>