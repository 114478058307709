<template>
  <div>
    <img src="comingsoon.png" />
  </div>
</template>
<script>
export default {
  name: "ComingSoon",
};
</script>

<style scoped>
img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*PORTRAIT */
@media screen and (orientation: portrait) {
  img {
    width: 150%;
    height: auto;
  }
}
/* iPhone X */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  img {
    width: 150%;
    height: auto;
  }
}

/* iPhoneXR */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  img {
    width: 150%;
    height: auto;
  }
}

/*LANDSCAPE */
@media screen and (orientation: landscape) {
  img {
    width: auto;
    height: 100%;
  }
}

/* iPhone X */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  img {
    width: auto;
    height: 100%;
  }
}

/* iPhoneXR */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
  img {
    width: auto;
    height: 100%;
  }
}
</style>