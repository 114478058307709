import InstaIcon from "../SVG/icon_insta.svg"
import FbIcon from "../SVG/icon_fb.svg";
import LinkIcon from "../SVG/icon_link.svg"

import Alkohol from "../SVG/Tags/alkohol-01.svg";
import PreisLeistung from "../SVG/Tags/preisleistung-01.svg";
import Regenfest from "../SVG/Tags/wetterfest-01.svg";
import Vegan from "../SVG/Tags/vegan-01.svg";
import VeganIcon from "../SVG/Tags/Vegan_farbig.svg";
import Sitzen from "../SVG/Tags/Sitzen-01.svg";



export default {
    components: {
        InstaIcon,
        FbIcon,
        LinkIcon,
        Alkohol,
        PreisLeistung,
        Regenfest,
        Vegan,
        Sitzen,
        VeganIcon
    },
    methods: {
        Check(field) {
            return field != null && field != "";
        },
        CheckArray(array) {
            return array != null && array.length > 0;
        },
        CloseArticle() {
            this.$store.state.currentArticle = null;
            history.pushState({}, "", `/map`);
          }
        

    }
}