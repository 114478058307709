<template>
  <div class="mainPages">
    <h1>Impressum</h1>
    <h4>Frittenradar.de</h4>
    Manteuffelstraße 6 <br> 
    10997 Berlin <br> 
    <br> 
    <h4>Kontakt:</h4>
    E-Mail: Pommes@frittenradar.de<br> 
    Website: www.frittenradar.de <br> 
      <br> 
    <h4>Bei redaktionellen Inhalten:</h4>
    Verantwortlich nach § 55 Abs.2 RStV<br> 
    <br> 
    <h4>Frittenradar</h4>
    Lea Schorling, Jannik Noeske, Paul Böttcher <br> 
    Manteuffelstraße 6<br> 
    10997 Berlin<br> 
  </div>
</template>

<script>
export default {
  name: "Imprint",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "../style/pages.scss";


h4{
font-size: 1.2rem;
}
</style>