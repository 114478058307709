var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('MglMap',{style:({ cursor: _vm.cursorOnMap }),attrs:{"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle,"center":_vm.getStartPosition,"zoom":_vm.getStartZoom,"id":"map"},on:{"load":_vm.onMapLoaded}},[_vm._l((this.$store.state.articles),function(article){return _c('MglMarker',{key:article.id,staticClass:"marker",attrs:{"coordinates":_vm.GetCoordinates(article.coordinates)},on:{"click":function (e) {
          _vm.ActivateMarker(article);
        }}},[(
          _vm.currentArticle != article &&
          _vm.GetCoordinates(article.coordinates).length != 0
        )?_c('template',{slot:"marker"}):_vm._e(),(
          _vm.currentArticle == article &&
          _vm.GetCoordinates(article.coordinates).length != 0
        )?_c('template',{slot:"marker"}):_vm._e(),(_vm.currentArticle != article && !article.rip)?_c('FrittenIcon',{staticClass:"marker",attrs:{"slot":"marker","width":"35px"},slot:"marker"},[_vm._v("ICON")]):_vm._e(),(article.rip)?_c('FrittenIconLeer',{staticClass:"marker",attrs:{"slot":"marker","width":"35px"},slot:"marker"},[_vm._v("ICON")]):_vm._e(),(!_vm.currentArticle != article && !article.rip)?_c('FrittenActionIcon',{staticClass:"marker",attrs:{"slot":"marker","width":"50px"},slot:"marker"},[_vm._v("ICON")]):_vm._e()],2)}),(_vm.location)?_c('MglMarker',{staticClass:"marker",attrs:{"coordinates":[_vm.location.coords.longitude, _vm.location.coords.latitude]}},[_c('template',{slot:"marker"}),_c('UserPosition',{staticClass:"blue",attrs:{"slot":"marker","width":"60px"},slot:"marker"})],2):_vm._e(),(_vm.newBudeLocation != null)?_c('MglMarker',{staticClass:"marker",attrs:{"coordinates":[_vm.newBudeLocation.lng, _vm.newBudeLocation.lat]}},[_c('template',{slot:"marker"}),_c('div',{staticClass:"addPanel",attrs:{"slot":"marker"},slot:"marker"},[_c('div',{staticClass:"AddButton",on:{"click":_vm.AddNewBude}},[_c('div',{style:('margin-right:10px;justify-content:center;')},[_c('h4',[_vm._v("Frittenbude")]),_c('h4',[_vm._v("eintragen")])]),_c('button',{staticClass:"btn-small"},[_c('div',{style:('transform:rotate(180deg);')},[_c('ArrowSimple')],1)])]),_c('UserPosition',{staticClass:"icon blue"})],1)],2):_vm._e()],2),(!this.$store.state.mobileIsOpen || !this.$store.state.isMobileView)?_c('div',{staticClass:"buttonContainer",style:({ bottom: _vm.buttonBottom })},[(this.location != null && this.mapMoved)?_c('ArrowGelb',{staticClass:"btn",on:{"click":function($event){return _vm.ResetPositon()}}}):_vm._e()],1):_vm._e(),(
      this.$store.state.currentArticle != null &&
      this.$store.state.isMobileView
    )?_c('ArticleMobile'):_vm._e(),_c('transition',{attrs:{"name":"moveright"}},[(
        this.$store.state.currentArticle != null &&
        !this.$store.state.isMobileView
      )?_c('Article',{staticClass:"article article-big"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }