<template>
  <div>
    <KlecksFull1
      v-if="this.visibleIdx == 0 && this.type == 'red'"
      :class="'point '"
      :style="cssProps"
    />
    <KlecksFull2
      v-if="this.visibleIdx == 1 && this.type == 'red'"
      :class="'point '"
      :style="cssProps"
    />
    <KlecksFull3
      v-if="this.visibleIdx == 2 && this.type == 'red'"
      :class="'point '"
      :style="cssProps"
    />
    <KlecksFull4
      v-if="this.visibleIdx == 3 && this.type == 'red'"
      :class="'point '"
      :style="cssProps"
    />

    <KlecksWhite1
      v-if="this.visibleIdx == 0 && this.type == 'white'"
      :class="'point '"
      :style="cssProps"
    />
    <KlecksWhite2
      v-if="this.visibleIdx == 1 && this.type == 'white'"
      :class="'point '"
      :style="cssProps"
    />
    <KlecksWhite3
      v-if="this.visibleIdx == 2 && this.type == 'white'"
      :class="'point '"
      :style="cssProps"
    />
    <KlecksWhite4
      v-if="this.visibleIdx == 3 && this.type == 'white'"
      :class="'point '"
      :style="cssProps"
    />
  </div>
</template>

<script>
import ArticleMixin from "../Mixins/article";
import KlecksFull1 from "../SVG/Rating/Fleck1_rot.svg";
import KlecksFull2 from "../SVG/Rating/Fleck2_rot.svg";
import KlecksFull3 from "../SVG/Rating/Fleck3_rot.svg";
import KlecksFull4 from "../SVG/Rating/Fleck3_rot.svg";

import KlecksWhite1 from "../SVG/Rating/Fleck1_weiss.svg";
import KlecksWhite2 from "../SVG/Rating/Fleck2_weiss.svg";
import KlecksWhite3 from "../SVG/Rating/Fleck3_weiss.svg";
import KlecksWhite4 from "../SVG/Rating/Fleck3_weiss.svg";

export default {
  components: {
    KlecksFull1,
    KlecksFull2,
    KlecksFull3,
    KlecksFull4,

    KlecksWhite1,
    KlecksWhite2,
    KlecksWhite3,
    KlecksWhite4,
  },
  mixins: [ArticleMixin],
  props: ["type"],
  data: () => {
    return {
      visibleIdx: 0,
    };
  },

  computed: {
    cssProps() {
      return {
        "--rotation": Math.random() * 360 + "deg",
      };
    },
  },

  methods: {},
  mounted() {
    this.visibleIdx = Math.round(Math.random() * 3);
  },
};
</script>

<style lang="scss" scoped>
.point {
  width: 2rem;
  height: auto;
  margin-right: 0.3rem;
  transform: rotate(var(--rotation));
}
</style>