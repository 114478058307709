<template>
  <div id="hinweis" >
    <div id="button-wrapper">
      <h3 class="title">Neue Bude eintragen</h3>
      <p class="text">
        Klick auf die Karte, um eine neue Bude zu markieren.
      </p>
      <button @click="Activate(true)">OK</button>
      <button class="einstellungen" @click="Activate(false)">
        Abbrechen
      </button>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "HinweisPanel",
  components: {},
  methods: {
    Activate(active) {
        this.$store.commit("SetShowHinweis", false);
        this.$store.commit("SetAdding", active);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";

#list {
  height: 40%;
}

#hinweis {
  position: absolute;
  background-color: $transparent-light;
  border: solid $border-width;
  top: 50%;
  left: 50%;
  z-index: 1002;
  overflow: auto;
  width: 300px;
  height: 300px;
  margin-left: -150px;
  margin-top: -150px;
  padding: 1.2em;
}

button {
  width: 100%;
  height: 55px;
  border: solid $border-width;
  background-color: $background-color;
  outline: 0;
}

#button-wrapper {
  height: 100%;
  width: 100%;
}
.einstellungen {
  border: none;
  background: none;
  outline: 0;
  color: $light-font-color;
}

.title{
  font-size: 1.5rem;
  margin-bottom:0.5em;;
}

.text{
  font-size:0.9rem;
}
</style>