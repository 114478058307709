import Promise from 'promise-polyfill';
export default {   
    methods: {
        getTagString() {
            var tagString = "";
            if (this.alc) {
                tagString = tagString.concat("Alkohol");
            }
            if (this.rain) {
                tagString = this.checkTagString(tagString)
                tagString = tagString.concat("Regenfest");
            }
            if (this.sit) {
                tagString = this.checkTagString(tagString)
                tagString = tagString.concat("Sitzgelegenheit");
            }
            if (this.preisleistung) {
                tagString = this.checkTagString(tagString)
                tagString = tagString.concat("PreisLeistung");
            }
            if (this.vegan) {
                tagString = this.checkTagString(tagString)
                tagString = tagString.concat("Vega");
            }
            return tagString;
        },

        checkTagString(tagString) {
            if (tagString != "") {
                tagString = tagString.concat(",");
            }
            return tagString
        },
        singleImageUpload(file) {
            const formData = new FormData();
            formData.append("fileUpload", file);
            return new Promise((resolve, reject) => {
                fetch(
                    `https://api-eu-central-1.graphcms.com/v2/ckgpjwosgfwq001xy7okc5vrn/master/upload`,
                    {
                        method: "POST",
                        headers: {
                            authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjU1OTgzNjAsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2NrZ3Bqd29zZ2Z3cTAwMXh5N29rYzV2cm4vbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiYjRlZTEzOGEtNzEyNC00YzVmLTk2NDktOWY5NzQyMTFlMDY2IiwianRpIjoiY2txc2ZhaXp2eG01eTAxeG1ncjdtaHY4ayJ9.eWbZLTJHZbCAOw-6j_8IZUqUyZ_5tnjl_ZQHC8wr5w1BAW_DzYvjMgxgULxTs6cA2Q-zYiRcZM-JKJsumtX5xsBRgQYkEQqzFtyXb5SMaqXz5kI9IbCvg-ILArOH0_2MU3fn7YBREcFfSjNspiaqXz_DrDCY-EWPIG6KlEoYqj5fodEDW9P2xQeX9xKfkzE0HCsE27axaNKgSkJdhPZE3esQmwZYYI2QU9Gz2ehERjkMUjRxMxz1NP_xzAtSWsvlY8tURPY_JsftpQGTAopitHKJVRr2AgfBw_tR_JuS4cJQ-g6bmBivJkl_vnlQiiM0sAU-swMxs2bWJYH07RXsH15L-qzxDYr4WrMWJSn-EFdA8fd4AjYA-WCI1ZpKeS-RXFuyNUS4FFv25kjucnMHQoxD-OZHu-ZvUdTC46Ga8C1BqZO1gf0L-VbDi2vDPOVzbTgwe9PCV1kHXAExjYtx7s-oze1K_4JwhinA4EKn1VUaFMJhU5LFrCH9Hu5RkjEMLc4dOoB2e6YdlJFwFZzCp3ORgJ7rm12HFPlzsARg1nKxu5r9e3pxkZ6s6rRcUOJG3HmJJUi87r48_amDlLC3A3_j5wxqCQxP8pfKWvDjqv_i29_8SbSskgbY-pXvbYwkkcMjHC3E7d5Y6haXjSMMAIBqWIsoyKUlEcilLyAF-9k`,
                        },
                        body: formData,
                    }
                )
                    .then((response) => response.json())
                    .then((result) => {
                        resolve(result.id);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        convertImageIds(ids) {
            var returnString = '';
            ids.forEach((id) => {
                returnString = this.checkTagString(returnString)
                returnString = returnString.concat('{ id: "' + id + '" }')
            })
            return returnString
        }
    }
}