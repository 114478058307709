<template>
  <div class="content">
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :center="getStartPosition"
      :zoom="getStartZoom"
      @load="onMapLoaded"
      id="map"
      :style="{ cursor: cursorOnMap }"
    >
      <MglMarker
        v-for="article in this.$store.state.articles"
        :key="article.id"
        :coordinates="GetCoordinates(article.coordinates)"
        @click="
          (e) => {
            ActivateMarker(article);
          }
        "
        class="marker"
      >
        <template
          slot="marker"
          v-if="
            currentArticle != article &&
            GetCoordinates(article.coordinates).length != 0
          "
        >
          <!--<img
          src="../SVG/FR_Icon_Marker.png"
          width="40"
          />-->
        </template>
        <template
          slot="marker"
          v-if="
            currentArticle == article &&
            GetCoordinates(article.coordinates).length != 0
          "
        >
          <!--<img
          src="../SVG/FR_Icon_Marker_action.png"
          width="40"
          />-->
        </template>
        <FrittenIcon
          v-if="currentArticle != article && !article.rip"
          class="marker"
          slot="marker"
          width="35px"
          >ICON</FrittenIcon
        >
        <FrittenIconLeer
          v-if="article.rip"
          class="marker"
          slot="marker"
          width="35px"
          >ICON</FrittenIconLeer
        >
        <FrittenActionIcon
          v-if="!currentArticle != article && !article.rip"
          slot="marker"
          width="50px"
          class="marker"
          >ICON</FrittenActionIcon
        >
      </MglMarker>

      <MglMarker
        v-if="location"
        :coordinates="[location.coords.longitude, location.coords.latitude]"
        class="marker"
      >
        <template slot="marker">
          <!--<img
          src="../SVG/FR_Icon_Marker.png"
          width="40"
          />-->
        </template>
        <UserPosition slot="marker" class="blue" width="60px" />
      </MglMarker>

      <MglMarker
        v-if="newBudeLocation != null"
        :coordinates="[newBudeLocation.lng, newBudeLocation.lat]"
        class="marker"
      >
        <template slot="marker"> </template>

        <div class="addPanel" slot="marker">
          <div class="AddButton" @click="AddNewBude">
            <div :style="'margin-right:10px;justify-content:center;'">
              <h4>Frittenbude</h4>
              <h4>eintragen</h4>
            </div>

            <button class="btn-small">
              <div :style="'transform:rotate(180deg);'">
                <ArrowSimple />
              </div>
            </button>
          </div>
          <UserPosition class="icon blue" />
        </div>
      </MglMarker>
    </MglMap>

    <div
      v-if="!this.$store.state.mobileIsOpen || !this.$store.state.isMobileView"
      class="buttonContainer"
      :style="{ bottom: buttonBottom }"
    >
      <!--<button v-if="this.location != null" class="btn" @click="ResetPositon()">
        RESET
      </button>-->
      <ArrowGelb
        class="btn"
        v-if="this.location != null && this.mapMoved"
        @click="ResetPositon()"
      />
      <!--<button
        :class="'btn active-' + this.$store.state.isAdding"
        @click="AddBude()"
      >
        ADD
      </button>-->

      <!-- <Plus v-if="this.$store.state.isAdding" class="btn" @click="AddBude()" />
      <PlusInactive
        v-if="!this.$store.state.isAdding"
        class="btn"
        @click="AddBude()"
      /> -->
    </div>

    <ArticleMobile
      v-if="
        this.$store.state.currentArticle != null &&
        this.$store.state.isMobileView
      "
    />

    <transition name="moveright">
      <Article
        class="article article-big"
        v-if="
          this.$store.state.currentArticle != null &&
          !this.$store.state.isMobileView
        "
      />
    </transition>

    <!--<router-link to="/submit" class="machmit"><Plus/></router-link>-->
  </div>
</template>

<script>
import { MglMap, MglGeojsonLayer, MglMarker, MglPopup } from "vue-mapbox";
import FrittenIcon from "../SVG/PommesMarker_V0-01.svg";
//import FrittenIcon from "../SVG/Marker/Pommes_rosa_weiss.svg";
import FrittenIconBlass from "../SVG/PommesMarker_V0_blass-01.svg";
import FrittenActionIcon from "../SVG/PommesMarker_V0_action.svg";
import FrittenIconLeer from "../SVG/PommesMarker_V0_leer-01.svg";
import Article from "./Article";
import ArticleMobile from "./ArticleMobile";
import UserPosition from "../SVG/Icons/Location.svg";
import Mapbox from "mapbox-gl";
import Plus from "../SVG/Pommesplus_blau.svg";
import PlusInactive from "../SVG/Pommesplus_gelb.svg";
import ArrowBlau from "../SVG/Pommespfeil_blau.svg";
import ArrowGelb from "../SVG/Pommespfeil_gelb.svg";
import ArrowSimple from "../SVG/up-arrow.svg";
import Kreuz from "../SVG/Icons/Pommeskreuz.svg";

export default {
  name: "Map",
  components: {
    MglMap,
    MglMarker,
    FrittenIcon,
    FrittenIconBlass,
    FrittenActionIcon,
    MglPopup,
    Article,
    ArticleMobile,
    UserPosition,
    FrittenIconLeer,
    Plus,
    PlusInactive,
    ArrowBlau,
    ArrowGelb,
    Kreuz,
    ArrowSimple,
  },
  data() {
    return {
      coordinates: [9.73322, 52.37052],
      zoom: 6,
      accessToken:
        "pk.eyJ1IjoiZnJpdHRlbnJhZGFyIiwiYSI6ImNrZ3BtanN0dTBkZHkyc25seTY2dDRjdTYifQ.mM2AWUewbaYnPJN4li4mVg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/frittenradar/ckucpneeg1eof17mvvssvz9fd", // your map style  --- mapbox://styles/frittenradar/ckgpmz9b60dge19r4v0m5z0vu
      articles: [],
      loading: true,
      errors: [],
      location: null,
      gettingLocation: false,
      errorStr: null,
      startLocation: null,
      map: null,
      newBudeLocation: null,
      mapMoved: false,
    };
  },
  watch: {},

  methods: {
    ResetPositon(pos) {
      this.startLocation = null;
      this.startLocation = this.location;
    },
    GetCoordinates(coordinates) {
      if (coordinates == null) {
        return [0, 0];
      }
      return [coordinates.longitude, coordinates.latitude];
    },
    ActivateMarker(article) {
      //Close current article
      if (article == this.$store.state.currentArticle) {
        this.$store.state.currentArticle = null;
        history.pushState({}, "", `/map`);
        return;
      }

      var id = article.id;
      history.pushState({}, "", `/map/${id}`);
      this.$store.state.currentArticle = article;
    },
    success(pos) {
      if (this.$store.state.isAdding) {
        return;
      }
      this.location = pos;
      this.zoom = 17;
      if (this.startLocation == null) {
        this.startLocation = pos;
        this.mapMoved = false;
      }
    },

    error() {
      //console.log("LOCATION NOT FOUND");
    },
    onMapLoaded(event) {
      event.map.on("click", async (e) => {
        if (this.newBudeLocation != null) {
          this.newBudeLocation = null;
        } else if (this.$store.state.currentArticle == null) {
          this.newBudeLocation = e.lngLat;
        }

        // if (this.$store.state.isAdding) {
        //   this.newBudeLocation = e.lngLat;
        // }
      });
      event.map.on("move", (e) => {
        if (this.location != null) {
          var center = event.map.getCenter();
          var loc = this.location.coords;
          //console.log("LOC ", this.location, " Center ", center);
          var a = center.lat - loc.latitude;
          var b = center.lng - loc.longitude;

          var dist = Math.sqrt(a * a + b * b);
          //console.log("DISTANCE ", dist);
          this.mapMoved = dist > 0.02;
        }
      });
    },
    AddBude() {
      this.$store.commit("SetShowHinweis", !this.$store.state.isAdding);
      this.$store.commit("SetAdding", !this.$store.state.isAdding);

      if (!this.$store.state.isAdding) {
        this.newBudeLocation = null;
      }
    },
    AddNewBude() {
      this.$store.commit("SetAdding", false);
      this.$router.push({
        name: "submit",
        params: {
          lng: this.newBudeLocation.lng,
          lat: this.newBudeLocation.lat,
        },
      });
    },
  },
  computed: {
    currentArticle: function () {
      return this.$store.state.currentArticle;
    },
    getStartPosition: function () {
      if (this.startLocation != null) {
        this.zoom = null;
        this.zoom = 12;
      }
      return this.startLocation == null
        ? this.coordinates
        : [
            this.startLocation.coords.longitude,
            this.startLocation.coords.latitude,
          ];
    },

    getStartZoom: function () {
      return this.zoom;
    },
    buttonBottom: function () {
      return !this.$store.state.isMobileView
        ? "10px"
        : this.$store.state.currentArticle == null
        ? "10px"
        : "250px";
    },
    cursorOnMap: function () {
      return this.$store.state.isAdding
        ? 'url("Group.svg") 16 16, pointer'
        : "grab";
    },
  },
  mounted() {
    this.newBudeLocation = null;
  },
  async created() {
    const response = await fetch(
      "https://api-eu-central-1.graphcms.com/v2/ckgpjwosgfwq001xy7okc5vrn/master",
      {
        method: "POST",
        body: JSON.stringify({
          query: `{  articles (first: 1000) {
                      id,
                      title,
                      coordinates{
                        longitude,
                        latitude
                      },
                      images {
                        url (transformation: {image: {resize: {width: 1024, fit: max}}})
                      },
                      description,
                      author,
                      openingHours,
                      price,
                      tags,
                      shopType,
                      frittenRating,
                      sauceRating,
                      ambienteRating,
                      adress,
                      facebookLink,
      instgrammLink,
      websiteLink,
      updatedAt,
      ungetestet,
      rip
                    } 
                  }`,
        }),
      }
    );

    const { data } = await response.json();
    this.$store.state.articles = data.articles;

    //console.log("ARTICLES ", this.$store.state.articles.length);

    this.errors = data.errors;
    this.loading = false;
    this.products = data.products;

    if (this.$route.params.id != undefined) {
      let id = this.$route.params.id;
      let articles = this.$store.state.articles.filter((a) => a.id == id);

      if (articles.length > 0) {
        this.$store.state.currentArticle = articles[0];
      }
    } else if (this.$store.state.currentArticle != null) {
      let id = this.$store.state.currentArticle.id;
      history.pushState({}, "", `/map/${id}`);
    }

    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }

    this.gettingLocation = true;

    //get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.gettingLocation = false;
        this.location = pos;
      },
      (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      }
    );

    navigator.geolocation.watchPosition(this.success, this.error);
  },
};
</script>
f
<style lang="scss" scoped>
@import "../style/variables.scss";

.marker {
  cursor: pointer;
}

.machmit {
  position: absolute;
  width: 80px;
  height: auto;
  left: 10px;
  bottom: 10px;
  z-index: 999;
  font-family: "Rainmaker";
}

.buttonContainer {
  position: absolute;
  width: 80px;
  height: auto;
  left: 10px;
  z-index: 999;
  font-family: "Rainmaker";
}

.btn {
  width: 50px;
  height: 50px;
  padding: 0px;

  &.active-true {
    background-color: $background-color;
  }
}

.btn-small {
  background: none;
  width: 30px;
  padding: 0px;
  border-style: none;

  &.active-true {
    background-color: $background-color;
  }
}

.red {
  fill: $dark-red;
}

.blue {
  fill: $blue;
}

.icon {
  width: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.addPanel {
  height: 200px;
  align-items: center;
  transform: translate(0, -50%);

  .AddButton {
    background-color: whitesmoke;
    justify-content: center;
    outline: black 1px solid;
    padding: 5px;
    display: flex;
    border-radius: 2px;
    h4 {
      cursor: pointer;
      margin-bottom: 0px;
    }
  }
}
</style>