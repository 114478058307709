<template>
  <div>
    <div
      v-if="this.$store.state.currentArticle.images.length > 0"
      class="article-image-container"
    >
      <Arrow
        class="arrow left"
        v-if="this.$store.state.currentArticle.images.length > 1"
        @click.stop="SetImageIdx(-1)"
      />
      <div class="image-container">
        <img
          v-if="CheckArray(this.$store.state.currentArticle.images)"
          :src="GetImage(this.$store.state.currentArticle.images)"
          class="article-image"
        />
      </div>
      <Arrow
        class="arrow right"
        v-if="this.$store.state.currentArticle.images.length > 1"
        @click.stop="SetImageIdx(1)"
      />
    </div>

    <div class="info-box abschnitt">
      <div
        v-if="Check(this.$store.state.currentArticle.adress)"
        class="row-container"
      >
        <Location class="icon blue row-item" />
        <p class="row-item text-col">
          {{ this.$store.state.currentArticle.adress }}
        </p>
      </div>

      <div
        v-if="Check(this.$store.state.currentArticle.openingHours)"
        class="row-container"
      >
        <Clock class="icon row-item" />
        <p class="row-item text-col" text-coltext-col>
          {{ this.$store.state.currentArticle.openingHours }}
        </p>
      </div>

      <div
        v-if="Check(this.$store.state.currentArticle.price)"
        class="row-container"
      >
        <Euro class="euro row-item" />
        <p class="row-item text-col">
          {{ this.$store.state.currentArticle.price }}
        </p>
      </div>
    </div>

    <div v-if="Check(this.$store.state.currentArticle.description)" class="abschnitt">
      <p >
        {{ this.$store.state.currentArticle.description }}
      </p>
    </div>

    <div
      v-if="
        this.$store.state.currentArticle.frittenRating != null ||
        this.$store.state.currentArticle.sauceRating != null ||
        this.$store.state.currentArticle.ambienteRating != null
      "
      class="abschnitt"
    >
      <h3>Das erwartet dich</h3>
      <!--<RatingTitle class="title"/>-->
      <Rating
        title="Fritten"
        :rating="this.$store.state.currentArticle.frittenRating"
      />
      <Rating
        title="Saucen"
        :rating="this.$store.state.currentArticle.sauceRating"
      />
      <Rating
        title="Ambiente"
        :rating="this.$store.state.currentArticle.ambienteRating"
      />
    </div>

    <div
      class="abschnitt"
      v-if="this.$store.state.currentArticle.tags.length != 0"
    >
      <h3>Dazu gibt's</h3>
      <div
        class="in-row"
        v-if="this.$store.state.currentArticle.tags.includes('Alkohol')"
      >
        <Haken class="haken" />
        <h4>Alkohol</h4>
      </div>
      <div
        class="in-row"
        v-if="this.$store.state.currentArticle.tags.includes('Regenfest')"
      >
        <Haken class="haken" />
        <h4>Regenschutz</h4>
      </div>
      <div
        class="in-row"
        v-if="this.$store.state.currentArticle.tags.includes('PreisLeistung')"
      >
        <Haken class="haken" />
        <h4>Faire Preise</h4>
      </div>
      <div
        class="in-row"
        v-if="this.$store.state.currentArticle.tags.includes('Sitzgelegenheit')"
      >
        <Haken class="haken" />
        <h4>Was zum Sitzen</h4>
      </div>
      <div
        class="in-row"
        v-if="this.$store.state.currentArticle.tags.includes('Vega')"
      >
        <Haken class="haken" />
        <h4>Veganes</h4>
        <VeganIcon class="icon" />
      </div>
    </div>

    <div
      v-if="
        this.$store.state.currentArticle.facebookLink != null ||
        this.$store.state.currentArticle.instgrammLink != null ||
        this.$store.state.currentArticle.websiteLink != null
      "
      class="abschnitt"
    >
      <h3>Mehr Infos hier</h3>
      <a
        v-if="Check(this.$store.state.currentArticle.facebookLink)"
        :href="this.$store.state.currentArticle.facebookLink"
        target="_blank"
      >
        <FbIcon class="icons dark" />
      </a>
      <a
        v-if="Check(this.$store.state.currentArticle.instgrammLink)"
        :href="this.$store.state.currentArticle.instgrammLink"
        target="_blank"
      >
        <InstaIcon class="icons dark" />
      </a>
      <a
        v-if="Check(this.$store.state.currentArticle.websiteLink)"
        :href="this.$store.state.currentArticle.websiteLink"
        target="_blank"
      >
        <LinkIcon class="icons dark" />
      </a>
    </div>

    <p v-if="Check(this.$store.state.currentArticle.author)" class="bold">
      Verkostet von: {{ this.$store.state.currentArticle.author }}
    </p>

    <p class="date">
      Letztes Update: {{ GetDate(this.$store.state.currentArticle.updatedAt) }}
    </p>
  </div>
</template>

<script>
import articleMixin from "../Mixins/article";
import Rating from "./Rating";
import Location from "../SVG/Icons/Location.svg";
import Clock from "../SVG/Icons/Clock.svg";
import Arrow from "../SVG/up-arrow.svg";
import Euro from "../SVG/Icons/EUR-Zeichen-02.svg";
import Haken from "../SVG/Tags/haken-01.svg";
import RatingTitle from "../SVG/Titel/rating-01.svg";
import VeganIcon from "../SVG/Tags/Vegan_farbig.svg";

export default {
  components: {
    Rating,
    Location,
    Clock,
    Arrow,
    Euro,
    Haken,
    Rating,
    RatingTitle,
    VeganIcon,
  },
  mixins: [articleMixin],
  data: function () {
    return {
      imageIdx: 0,
    };
  },
  methods: {
    GetDate(date) {
      var date = new Date(date);
      var stringDate =
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
      return stringDate;
    },
    GetImage(images) {
      if (images[this.imageIdx] != undefined) {
        return images[this.imageIdx].url;
      }
      return "";
    },
    SetImageIdx(direction) {
      var newIdx =
        (this.imageIdx + direction) %
        this.$store.state.currentArticle.images.length;
      this.imageIdx =
        newIdx < 0
          ? this.$store.state.currentArticle.images.length - 1
          : newIdx;
    },
  },
};
</script>

<style scoped>
.icons {
  width: 10%;
  height: auto;
  margin-right: 10px;
}

.icon {
  height: 40px;
  margin-left: 10px;
}

.euro {
  width: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

.tag {
  width: auto;
  height: 3rem;
}

.haken {
  height: 1rem;
  width: auto;
  margin-right: 0.5rem;
}

.arrow {
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  cursor: pointer;
}
.left {
  left: 0px;
  z-index: 99;
}
.right {
  right: 0px;
  transform: rotate(180deg);
}
.article-image-container {
  position: relative;
}

.image-container {
  width: 100%;
  padding-top: 70%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
  overflow: hidden;
}

.article-image {
  object-fit: scale-down;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.info-box {
  padding: 1rem;
  font-size: 85%;
}

.abschnitt {
  margin-bottom: 1.2rem;
  padding-bottom: 0.7rem;
  border-bottom: solid black 1px;
}

.row-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.4rem;
}

.row-item {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.text-col {
  width: 90%;
  padding-left: 0.2rem;
}
.date {
  margin-top: 2rem;
  margin-bottom: 0;
  text-align: right;
  font-size: 0.7rem;
}

.in-row {
  display: flex;
  align-items: center;
}

h4 {
  margin-bottom: 0px;
}

.title {
  height: 2rem;
  width: auto;
}

.foot-line {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  width: 100%;
  margin-top: 5rem;
  font-size: 0.2rem;
}
</style>