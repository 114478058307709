<template>
  <div class="mainPages">
    <h1 v-if="!submitted && !loading">Mach Mit!</h1>

    <div id="form-intro" v-if="!loading">
      <p>Alle freuen sich über neue Buden!</p>
      <p>
        Füll einfach das Formular aus und bald erscheint eine neue Tüte auf der
        Karte :)
      </p>
      <!--<p>Deine Lieblings-Frittenbude ist noch nicht gelistet?</p>
      <p>
        Oder bist du einer Frittenbude über den Weg gelaufen, die unbedingt auf
        die Karte gehört?
      </p>
      <p>
        Dann füll einfach das Formular aus und werde Gastautor*in, um deine Bude
        zu featuren. Wenn du Fragen hast oder dir das Formular zu kompliziert
        ist, dann schicke uns eine Mail an
        <a href="mailto:pommes@frittenradar.de">pommes@frittenradar.de</a>.
      </p>-->
    </div>

    <div class="form" v-if="!submitted && !loading">
      <label for="ftitle">Name*</label>
      <input
        type="text"
        v-model="title"
        name="ftitle"
        placeholder="Wie heißt die Frittenbude?"
        required
      />

      <label>Bilder (bitte in Querformat)</label>
      <br />
      <label class="upload-btn" for="fimage">Bilder auswählen</label>
      <div v-if="this.images.length == 0">Keine Bilder ausgewählt.</div>
      <div v-if="this.images.length != 0">
        <div v-for="(img, idx) in this.images" :key="idx" class="Image">
          <PommesSingle class="pommesSingle" /> {{ img.name }}
        </div>
      </div>

      <input
        type="file"
        multiple
        accept="image/*"
        @change="setImages($event)"
        id="fimage"
        hidden
      />
      <br />

      <label for="fdescription">Kurze Beschreibung</label>
      <textarea
        id="subject"
        v-model="description"
        name="fdescription"
        placeholder="Was macht den Laden besonders?"
        class="textarea"
      ></textarea>

      <label for="faddress">Adresse</label>
      <input
        type="text"
        v-model="address"
        placeholder="z.B. Musterstraße 1, 11010 Berlin"
      />

      <label for="fprice">Preis</label>
      <input
        type="number"
        v-model="price"
        placeholder="...für eine kleine Portion?"
      />

      <label for="fopeninghours">Öffnungszeiten</label>
      <input type="text" v-model="openinghours" placeholder="Öffnungszeiten" />

      <h3>Bewertungen</h3>
      <p>1=nicht nochmal, 5=derbe geil</p>
      <label for="fratingfries">Fritten</label>
      <select class="select" v-model="ratingFries">
        <option disabled value="">Bitte auswählen</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </select>
      <br />

      <label for="fratingsauce">Saucen</label>
      <select class="select" v-model="ratingSauce">
        <option disabled value="">Bitte auswählen</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </select>
      <br />
      <label for="fratingatmo">Ambiente</label>
      <select class="select" v-model="ratingAtmo">
        <option disabled value="">Bitte auswählen</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
      </select>

      <h3>Was gibt es noch?</h3>
      <br />

      <label class="checkbox" for="frain">
        <input type="checkbox" v-model="rain" />Regenschutz?
      </label>

      <br />

      <label class="checkbox" for="fsit">
        <input type="checkbox" v-model="sit" />Kann man sitzen?
      </label>
      <br />

      <label class="checkbox" for="fpreisleistung">
        <input type="checkbox" v-model="preisleistung" />Preis-Leistung ist top?
      </label>

      <br />

      <label class="checkbox" for="fvegan">
        <input type="checkbox" v-model="vegan" />Vegan friendly?
      </label>
      <br />

      <label class="checkbox" for="falc">
        <input type="checkbox" v-model="alc" />Gibt es Alkohol?
      </label>
      <br />
      <!--
      <h3>Ladentyp</h3>
      <br />
      <label class="radio" for="franchise"
        >Kette
        <input
          type="radio"
          checked="checked"
          id="franchise"
          name="placetype"
          value="franchise"
          v-model="franchise"
        />
      </label>

      <br />

      <label class="radio" for="burger"
        >Burger-/Dönerladen
        <input
          type="radio"
          checked="checked"
          id="burger"
          name="placetype"
          value="burger"
          v-model="burger"
        />
      </label>

      <br />
      <label class="radio" for="imbiss"
        >Imbiss
        <input
          type="radio"
          checked="checked"
          id="imbiss"
          name="placetype"
          value="imbiss"
          v-model="imbiss"
        />
      </label>

      <br />

      <label class="radio" for="restaurant"
        >Pommes-Restaurant
        <input
          type="radio"
          checked="checked"
          id="restaurant"
          name="placetype"
          value="restaurant"
          v-model="restaurant"
        />
      </label>-->

      <br />

      <label for="fInsta">Instagram</label>
      <input
        class="insta"
        type="text"
        v-model="insta"
        placeholder="@Instagram"
      />

      <label for="fFacebook">Facebook</label>
      <input
        type="text"
        v-model="facebook"
        name="fFacebook"
        placeholder="@Facebook"
      />

      <label for="fWebsite">Webseite</label>
      <input
        type="text"
        v-model="website"
        name="fWebsite"
        placeholder="www...."
      />

      <label for="fAuthor">Dein Name</label>
      <input
        type="text"
        v-model="author"
        name="fAuthor"
        placeholder="Wie heißt du?"
      />

      <button v-if="isValid" @click="(e) => Send()">Abschicken</button>
      <h3 class="invalid" v-if="!isValid">*Bitte Namen eingeben</h3>
    </div>

    <div v-if="!submitted && loading" class="loading">
      <h1>Lädt...</h1>
      <PommesSingle class="pommes-animation" />
    </div>

    <div v-if="submitted">
      <h1>Danke :)</h1>
      <p>
        Wir schauen uns deinen Beitrag an und dann kommt er hoffentlich bald auf
        die Seite.
      </p>
    </div>
  </div>
</template>




<script>
import instaIcon from "../SVG/icon_insta.svg";
import { GraphQLClient } from "graphql-request";
import UploadMixin from "../Mixins/upload";
import PommesSingle from "../SVG/PommesSingle.svg";
import axios from "axios";
export default {
  name: "Mitmachen",
  components: {
    instaIcon,
    PommesSingle,
  },
  mixins: [UploadMixin],
  data: function () {
    return {
      title: null,
      description: null,
      address: null,
      price: null,
      openinghours: null,
      website: null,
      insta: null,
      facebook: null,
      author: null,
      ratingFries: null,
      ratingSauce: null,
      ratingAtmo: null,
      alc: false,
      rain: false,
      sit: false,
      preisleistung: false,
      vegan: false,
      franchise: null,
      burger: null,
      imbiss: null,
      restaurant: null,
      images: [],
      lng: null,
      lat: null,
      loading: false,
      submitted: false,
    };
  },
  computed: {
    // a computed getter
    isValid: function () {
      return this.title != null && this.title != "";
    },
  },
  mounted() {
    if (
      this.$route.params.lng != undefined &&
      this.$route.params.lat != undefined
    ) {
      this.lng = this.$route.params.lng;
      this.lat = this.$route.params.lat;

      axios
        .get(
          "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
            this.lng +
            "," +
            this.lat +
            ".json?access_token=pk.eyJ1IjoiZnJpdHRlbnJhZGFyIiwiYSI6ImNrZ3BtanN0dTBkZHkyc25seTY2dDRjdTYifQ.mM2AWUewbaYnPJN4li4mVg"
        )
        .then((res) => {
          var street = res.data.features[0].text;
          var number = res.data.features[0].address;
          var postcode = res.data.features[1].text;
          var city = res.data.features[3].text;

          var addressString =
            res.data.features[0].place_type[0] == "address"
              ? street + " " + number + ", " + postcode + " " + city
              : postcode + " " + city;

          if ((res.data.features[0].place_name = !null)) {
            this.address = addressString;
          }
        })
        .catch((error) => {
          //console.error(error);
        });
    }
  },
  methods: {
    setImages(event) {
      this.images = event.target.files;
    },

    Send() {
      this.loading = true;
      var tagString = this.getTagString();

      var promises = [];
      Object.values(this.images).forEach((file) => {
        promises.push(this.singleImageUpload(file));
      });
      Promise.all(promises).then((imageIds) => {
        this.createArticle(tagString, this.convertImageIds(imageIds));
      });
    },

    createArticle(tagString, imageIds) {
      const graphcms = new GraphQLClient(
        "https://api-eu-central-1.graphcms.com/v2/ckgpjwosgfwq001xy7okc5vrn/master",
        {
          headers: {
            authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjU1OTgzNjAsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2NrZ3Bqd29zZ2Z3cTAwMXh5N29rYzV2cm4vbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiYjRlZTEzOGEtNzEyNC00YzVmLTk2NDktOWY5NzQyMTFlMDY2IiwianRpIjoiY2txc2ZhaXp2eG01eTAxeG1ncjdtaHY4ayJ9.eWbZLTJHZbCAOw-6j_8IZUqUyZ_5tnjl_ZQHC8wr5w1BAW_DzYvjMgxgULxTs6cA2Q-zYiRcZM-JKJsumtX5xsBRgQYkEQqzFtyXb5SMaqXz5kI9IbCvg-ILArOH0_2MU3fn7YBREcFfSjNspiaqXz_DrDCY-EWPIG6KlEoYqj5fodEDW9P2xQeX9xKfkzE0HCsE27axaNKgSkJdhPZE3esQmwZYYI2QU9Gz2ehERjkMUjRxMxz1NP_xzAtSWsvlY8tURPY_JsftpQGTAopitHKJVRr2AgfBw_tR_JuS4cJQ-g6bmBivJkl_vnlQiiM0sAU-swMxs2bWJYH07RXsH15L-qzxDYr4WrMWJSn-EFdA8fd4AjYA-WCI1ZpKeS-RXFuyNUS4FFv25kjucnMHQoxD-OZHu-ZvUdTC46Ga8C1BqZO1gf0L-VbDi2vDPOVzbTgwe9PCV1kHXAExjYtx7s-oze1K_4JwhinA4EKn1VUaFMJhU5LFrCH9Hu5RkjEMLc4dOoB2e6YdlJFwFZzCp3ORgJ7rm12HFPlzsARg1nKxu5r9e3pxkZ6s6rRcUOJG3HmJJUi87r48_amDlLC3A3_j5wxqCQxP8pfKWvDjqv_i29_8SbSskgbY-pXvbYwkkcMjHC3E7d5Y6haXjSMMAIBqWIsoyKUlEcilLyAF-9k`,
          },
        }
      );

      var des = "";

      if (this.description != null) {
        des = this.description.replaceAll('"', "'");
        des = des.replaceAll("\n", " ");
      }

      this.lng = this.lng == null ? 0 : this.lng;
      this.lat = this.lat == null ? 0 : this.lat;

    
      if (isNaN(parseFloat(this.price))) {
        this.price = null;
      }

      var requestString =
        'mutation{createArticle(data: { title: "' +
        this.title +
        '",adress:"' +
        this.address +
        '",coordinates:{latitude:' +
        this.lat +
        ", longitude:" +
        this.lng +
        "},price:" +
        this.price +
        ',openingHours:"' +
        this.openinghours +
        '",author:"' +
        this.author +
        '",description:"' +
        des +
        '",facebookLink:"' +
        this.facebook +
        '",instgrammLink:"' +
        this.insta +
        '",websiteLink:"' +
        this.website +
        '",frittenRating:' +
        this.ratingFries +
        ",sauceRating:" +
        this.ratingSauce +
        ",ambienteRating:" +
        this.ratingAtmo +
        ",tags:[" +
        tagString +
        "],images:{connect:[" +
        imageIds +
        "]}}){id}}";

      const createArticle = graphcms
        .request(requestString)
        .then((res) => {
          this.submitted = true;
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/pages.scss";
@import "../style/variables.scss";

.icon {
  width: 30px;
}

#form-intro {
  width: 66%;
}

.textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  background-color: $yellow-light;
  resize: none;
  font-family: "inconsolata";
}

label {
  font-family: "Children";
  font-size: 1.2rem;
  font-style: bold;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 25px;
  box-sizing: border-box;
  border: none;
  font-family: "inconsolata";
  background-color: $yellow-light;
  color: grey;
  border-bottom: solid 2px transparent;
}
input[type="file"] {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 25px;
  box-sizing: border-box;
  border: none;
  font-family: "inconsolata";
  background-color: $yellow-light;
  color: grey;
}

input[type="checkbox"] {
  left: 0px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
  border: solid black 1px;
  border-radius: 1px;
  background-color: white;
  -webkit-appearance: none;
}

input[type="checkbox"]:checked {
  background-color: $blue;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  border-color: $background-color;
}

input[type="number"] {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 25px;
  box-sizing: border-box;
  border: none;
  font-family: "inconsolata";
  background-color: $yellow-light;
  color: grey;
  border-bottom: solid 2px transparent;
}

input[type="radio"] {
  position: absolute;
  display: block;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
  background: black;
}

.radio {
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "inconsolata";
  font-size: 1em;
}

.select {
  width: 100px;
  height: 50px;
  padding: 12px 12px;
  margin: 15px 15px;
  font-family: "inconsolata";

  border-style: solid;
  border-radius: 1px;
  border-color: black;
  border-width: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background: white
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
  background-position: right 5px top 50%;
  background-repeat: no-repeat;
}

button {
  background-color: $yellow-light$yellow-light;
  border: none;
  font-family: "Inconsolata";
  font-size: 1.5rem;
  padding: 1rem 2rem;
  margin-top: 30px;
}

button:hover {
  outline: 2px solid black;
}

.form {
  width: 70%;
  background-color: $transparent-light;
}

h3 {
  margin-top: 20px;
  margin-bottom: 0;
}
.invalid {
  color: $dark-red;
}

input:focus {
  color: black;
  outline: none;
  border-bottom: solid 2px black;
  background-color: transparent;
}

.upload-btn {
  background-color: $background-color;
  padding: 8px;
  border: solid black 1px;
  border-radius: 1px;
}

.pommesSingle {
  width: 30px;
  height: auto;
  margin-right: 5px;
}

.Image {
  display: flex;
  align-items: center;
}

.pommes-animation {
  animation-name: spin;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transform: rotate(0);
  width: 4rem;
  height: auto;
  margin-left: 2rem;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .form {
    width: 100%;
    background-color: $transparent-light;
  }

  #form-intro {
    width: 100%;
  }
}
</style>
