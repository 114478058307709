<template>
  <div
    id="mobileArticle"
    :class="'article article-small isOpen-' + this.$store.state.mobileIsOpen"
  >
    <div class="headline">
      <h1>{{ this.$store.state.currentArticle.title }}</h1>
      <Arrow
        :class="'arrow rotate-' + this.$store.state.mobileIsOpen"
        @click="OpenClose()"
      />
    </div>
    <ArticleContent />
  </div>
</template>

<script>
import Arrow from "../SVG/up-arrow.svg";
import Swipe from "./Swipe.js";
import ArticleContent from "./ArticleContent";

import articleMixin from "../Mixins/article";

export default {
  name: "ArticleMobile",
  components: {
    Map,
    Arrow,
    ArticleContent,
  },
  mixins: [articleMixin],

  methods: {
    OpenClose(mode = null) {
      if (mode != null) {
        this.$store.commit("SetMobileArticleOpen", mode);
      } else {
        this.$store.commit(
          "SetMobileArticleOpen",
          !this.$store.state.mobileIsOpen
        );
      }
    },
  },
  mounted() {
    var swiper = new Swipe(document.getElementById("mobileArticle"));
    swiper.run();

    swiper.onUp(() => {
      this.OpenClose(true);
    });

    swiper.onDown(() => {
      if (!this.$store.state.mobileIsOpen) {
        this.CloseArticle();
      } else if (document.getElementById("mobileArticle").scrollTop != 0) {
        return;
      }
      this.OpenClose(false);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";
.singleLine {
  margin-bottom: 0;
}
.arrow {
  width: 40px;
  transition: transform 1s;
  display: inline;
}

.rotate-true {
  transform: rotate(-90deg);
}
.rotate-false {
  transform: rotate(90deg);
}

h1 {
  display: inline;
  margin-bottom: 0;
}
.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.sterne {
  width: 150px;
  display: inline-block;
  left: 100px;
  position: absolute;
}
.tag {
  width: 20%;
}

.icons {
  margin-top: 20px;
  width: 10%;
  margin-right: 10px;
}

.article-small {
  position: fixed;
  bottom: 0px;
  width: 100%;
  border-radius: 20px 20px 0 0;
  transition: height 1s;
  padding: 1rem;
}
.isOpen-true {
  height: calc(100% - #{$navigation-height});
  overflow: scroll;
}
.isOpen-false {
  height: 30%;
  overflow: hidden;
}
</style>