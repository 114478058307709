<template>
  <div id="cookies" v-if="show">
    <div id="button-wrapper" v-if="showSettings == false">
      <h3 class="title">Fritten und Cookies</h3>
      <p class="text">
        Cookies schmecken nicht so gut wie Fritten, aber sie helfen uns, unsere
        Seite weiterzuentwickeln.
      </p>
      <button @click="SetCookies(false)">Alle Cookies akzeptieren</button>
      <button class="einstellungen" @click="ShowSettings(true)">
        Einstellungen
      </button>
    </div>

    <div id="button-wrapper" v-if="showSettings == true">
      <h3>Fritten und Cookies</h3>
      <p>
        Wir benutzen die Services von clicky.com, einem Webanalysedienst von
        Roxr Software, Ltd. Dieser Webanalysedienst sammelt deine Daten. Er
        verwendet Textdateien (Cookies), die auf deinem Computer gespeichert
        werden, die eine Analyse der Benutzung der Website durch dich ermöglicht
        und echte von unechten Besuchern zu unterscheiden. Die durch den Cookie
        erzeugten Informationen über deine Benutzung dieser Website
        (einschließlich Ihrer IP-Adresse) wird an einen Server nach Portland,
        OR, USA übertragen und dort gespeichert. Deine IP-Adresse wird umgehend
        anonymisiert. Clicky Web Analytics wird diese Informationen benutzen, um
        Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um
        weitere mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen zu erbringen. Auch wird Clicky Web Analytics diese
        Informationen gegebenenfalls an Dritte übertragen, sofern dies
        gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von
        Clicky Web Analytics verarbeiten. Clicky Web Analytics wird in keinem
        Fall deine IP-Adresse mit anderen Daten von Clicky Web Analytics in
        Verbindung bringen. Weitere Informationen unter Datenschutz.
      </p>
      <button @click="SetCookies(true)">Notwendige Cookies akzeptieren</button>
      <!--<button class="einstellungen" @click="ShowSettings(false)">Zurück</button>-->
    </div>
  </div>
</template>
      
<script>
export default {
  name: "CookiesPanel",
  components: {},
  data: function () {
    return {
      show: true,
      showSettings: false,
    };
  },
  methods: {
    ShowSettings(open) {
      this.showSettings = open;
    },
    SetCookies(disabled) {
      if (!disabled) {
        document.cookie = "clicky";

        /*var node = document.createElement("script"); // is a node
        node.innerHTML =
          "var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(101299226)";
        document.head.appendChild(node);*/
      }
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";

#list {
  height: 40%;
}

#cookies {
  position: absolute;
  background-color: $transparent-light;
  border: solid $border-width;
  top: 50%;
  left: 50%;
  z-index: 1002;
  overflow: auto;
  width: 300px;
  height: 300px;
  margin-left: -150px;
  margin-top: -150px;
  padding: 1.2em;
}

button {
  width: 100%;
  height: 55px;
  border: solid $border-width;
  background-color: $background-color;
  outline: 0;
  word-spacing: 0.3rem;
}

#button-wrapper {
  height: 100%;
  width: 100%;
}
.einstellungen {
  border: none;
  background: none;
  outline: 0;
  color: $light-font-color;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 0.5em;
}

.text {
  font-size: 0.9rem;
}
</style>