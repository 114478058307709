<template>
  <div class="rating-container" v-if="rating != null">
    <div class="title">
      <h4 v-if="Check(rating)">{{ title }}</h4>
    </div>
    <div class="klecks-container">
      <Klecks v-for="(k, idx) in this.rating" :key="idx" type="red"/>

      <Klecks v-for="(k, idx) in 5-this.rating" :key="idx+10" type="white"/>
    </div>
  </div>
</template>

<script>
import ArticleMixin from "../Mixins/article";
import Klecks from "./Klecks.vue";

export default {
  components: {
    Klecks,
  },
  mixins: [ArticleMixin],
  props: ["title", "rating"],
  methods: {},
};
</script>

<style scoped>
.rating-container {
  align-content: center;
  margin-bottom: 1rem;
  vertical-align: middle;
  display: flex;
}
.title {

  width: 80px;
}
.klecks-container {
  display: flex;
}
</style>